<template>
    <div>
        <v-container
            id="sessions"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="6" cols="12" class="d-flex">
                        <v-toolbar-title class="headline"
                            >Engage</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedMinistryPage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="engageItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                    <v-col md="auto" cols="12" class="d-inline-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Sort By"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="sorts"
                            style="max-width: 11.25rem"
                            clearable
                            @click:clear="table.options.sortBy = null"
                            v-model="table.options.sortBy"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Show"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="perPageItems"
                            style="max-width: 16rem; color: black !important"
                            clearable
                            @click:clear="table.pagination.perPage = 10"
                            v-model="table.pagination.perPage"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col md="6" lg="auto" cols="12">
                        <!--                        <v-btn-->
                        <!--                            class="border-radius-25 px-5 mx-1"-->
                        <!--                            depressed-->
                        <!--                            rounded-->
                        <!--                            dark-->
                        <!--                            color="#2C423D"-->
                        <!--                            @click="openSessionFormDialog('add')"-->
                        <!--                            >Add Session</v-btn-->
                        <!--                        >-->
                    </v-col>
                    <v-col md="6" lg="auto" cols="12" class="d-flex flex-row">
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            style="max-width: 11.25rem"
                            color="secondary"
                            placeholder="Filter by Group"
                            readonly
                            @click="isSearchDialogOpen = true"
                            clearable
                            @click:clear="table.options.group = null"
                            v-model="table.options.group"
                        ></v-text-field>
                        <div class="mx-1"></div>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by session Date"
                            color="secondary"
                            append-icon="mdi-calendar-outline"
                            style="max-width: 20rem"
                            readonly
                            :value="dateRangeDescription"
                            @click="isDateRangeDialogOpen = true"
                            clearable
                            @click:clear="clearDateRanges"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col>
                        <v-data-table
                            class="custom-table"
                            :headers="table.headers"
                            :loading="table.loading"
                            :items="table.items"
                            :server-items-length="table.pagination.total"
                            :items-per-page.sync="table.pagination.perPage"
                            :page.sync="table.pagination.page"
                            :footer-props="{
                                'items-per-page-options':
                                    table.pagination.itemsPerPageOptions,
                            }"
                            hide-default-footer
                        >
                            <template v-slot:item.date="{ item }">
                                <span>{{ formatDate(item.date) }}</span>
                            </template>
                            <template v-slot:item.members="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">{{
                                            toMembersCount(item.members).total
                                        }}</span>
                                    </template>
                                    <span
                                        >{{
                                            toMembersCount(item.members)
                                                .confirmed
                                        }}
                                        Confirmed |
                                        {{
                                            toMembersCount(item.members)
                                                .declined
                                        }}
                                        Declined
                                    </span>
                                </v-tooltip>
                            </template>

                            <!-- <template v-slot:item.inviteLink="{ item }">
                                <span
                                    ><span class="text-decoration-underline">{{
                                        item.session_url
                                    }}</span>
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="
                                                    copyToClipboard(
                                                        item.session_url
                                                    )
                                                "
                                                icon
                                                ><v-icon small color="#369776"
                                                    >mdi-content-copy</v-icon
                                                ></v-btn
                                            >
                                        </template>
                                        <span>Copy Invite Link</span>
                                    </v-tooltip></span
                                >
                            </template> -->
                            <template v-slot:item.actions="{ item }">
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#78756A20'"
                                        rounded
                                        @click="
                                            openSessionInformationDialog(item)
                                        "
                                    >
                                        <v-icon
                                            :color="hover ? '#fff' : '#78756A'"
                                            >mdi-fullscreen</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </template>
                        </v-data-table>
                        <Pagination
                            :total-pages="table.pagination.lastPage"
                            :current-page="table.pagination.currentPage"
                            @change="onPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <engage-session-information-dialog
            :show.sync="sessionInformationDialog.show"
            :data="sessionInformationDialog.data"
            @close="
                sessionInformationDialog = {
                    ...sessionInformationDialog,
                    show: false,
                    data: null,
                }
            "
            v-if="sessionInformationDialog.show"
        ></engage-session-information-dialog>

        <custom-search-dialog
            :is-open.sync="isSearchDialogOpen"
            title="Search Group"
            :action="getGroupsAction"
            item-text="name"
            @onSelect="onSelectGroup"
        ></custom-search-dialog>

        <base-date-range-dialog
            :is-open.sync="isDateRangeDialogOpen"
            :start-date.sync="table.options.dateFrom"
            :end-date.sync="table.options.dateTo"
            :selected-date-range.sync="dateRangeDescription"
        ></base-date-range-dialog>
    </div>
</template>

<script>
import Pagination from "../../../Core/Pagination.vue";
import BaseDateRangeDialog from "@/components/Base/BaseDateRangeDialog";
import dateUtility from "@/mixins/date";
import { GET_SESSIONS } from "@/store/modules/session";
import helperUtility from "@/mixins/helper";
import { GET_GROUPS } from "@/store/modules/group";
import CustomSearchDialog from "@/components/Custom/CustomSearchDialog";
import EngageSessionInformationDialog from "@/components/MainView/Engage/Sessions/components/SessionInformationDialog";

export default {
    name: "ministry-sessions",

    components: {
        EngageSessionInformationDialog,
        CustomSearchDialog,
        Pagination,
        BaseDateRangeDialog,
    },

    data() {
        return {
            table: {
                items: [],
                options: {
                    dateFrom: null,
                    dateTo: null,
                    group: null,
                    sortBy: "desc",
                },
                headers: [
                    {
                        text: "Session Title",
                        value: "title",
                        sortable: false,
                    },
                    {
                        text: "Session Date",
                        value: "date",
                        sortable: false,
                    },
                    {
                        text: "Group Name",
                        value: "group.name",
                        sortable: false,
                    },
                    {
                        text: "Members",
                        value: "members",
                        sortable: false,
                    },
                    {
                        text: "Invite Link",
                        value: "inviteLink",
                        sortable: false,
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        width: "130px",
                    },
                ],
                search: null,
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    itemsPerPageOptions: [5, 10, 15, 20],
                    lastPage: 1,
                    currentPage: 1,
                },
            },
            isDateRangeDialogOpen: false,
            dateRangeDescription: null,
            sessionForm: { show: false, action: "", data: null },
            sessionInformationDialog: { show: false, data: null },
            selectedMinistryPage: "/engage/sessions",
            engageItems: [
                {
                    text: "Sessions",
                    value: "/engage/sessions",
                },
                {
                    text: "Prayer Requests",
                    value: "/engage/prayer-requests",
                },
                {
                    text: "#TakeAMomentToPray",
                    value: "/engage/daily-devotions",
                },
                {
                    text: "Events Calendar",
                    value: "/engage/events-calendar",
                },
            ],
            show: false,
            isSearchDialogOpen: false,
            getGroupsAction: GET_GROUPS,
        };
    },

    computed: {
        sorts() {
            return this.$store.state.selection.sorts;
        },

        perPageItems() {
            return this.$store.state.selection.perPageItems;
        },
    },

    watch: {
        ["table.options"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getSessions();
            },
            deep: true,
        },
    },

    methods: {
        async getSessions() {
            const payload = {
                ...this.table.pagination,
                ...this.table.options,
            };
            this.table.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_SESSIONS,
                payload
            );
            if (success) {
                this.table.pagination.currentPage = data.meta.current_page || 0;
                this.table.pagination.total = data.meta.total || 0;
                this.table.pagination.lastPage = data.meta.last_page || 0;
                this.table.items = [...data.data];
                this.table.loading = false;
                return;
            }
            this.table.loading = false;
        },

        async onPaginationChange(page) {
            this.table.pagination.page = page;
            await this.getSessions();
        },

        async openSessionInformationDialog(data) {
            this.sessionInformationDialog = Object.assign(
                {},
                {
                    data: Object.assign({}, data),
                    show: true,
                }
            );
        },

        openSessionFormDialog(action) {
            switch (action) {
                case "add":
                    this.sessionForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.sessionForm = {
                        show: true,
                        action,
                        data: Object.assign(
                            {},
                            this.sessionInformationDialog.data
                        ),
                    };
                    this.sessionInformationDialog.show = false;
                    break;
                case "delete":
                    break;
                default:
                    this.sessionForm = { show: true, action, data: null };
                    break;
            }
        },

        clearDateRanges() {
            this.table.options.dateFrom = null;
            this.table.options.dateTo = null;
        },

        onOpenUpdateSessionForm({ data }) {
            this.sessionInformationDialog = Object.assign(
                {},
                {
                    show: false,
                    data: Object.assign({}, data),
                }
            );
            this.openSessionFormDialog("edit");
        },

        formatDate(timestamp) {
            return dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(timestamp)
            );
        },

        changeView(to) {
            this.$router.push(to);
        },

        toMembersCount(members) {
            const confirmed = members.confirmed.length || 0;
            const declined = members.declined.length || 0;
            return {
                confirmed: confirmed,
                declined: declined,
                total: confirmed + declined,
            };
        },

        copyToClipboard(text) {
            helperUtility.copyText(text);
            this.$notify({
                group: "main-notifications",
                type: "success",
                title: "Copied to Clipboard",
                text: "Session URL was copied to clipboard.",
            });
        },

        onSelectGroup(group) {
            this.table.options.group = group.name;
        },

        async initiate() {
            this.selectedMinistryPage = this.$route.path;
            await this.getSessions();
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.ministry-pages::v-deep .v-select__selection--comma {
    color: #78756a;
}
</style>
